import config from '@/js/config'

const toast = {
  color: 'success',
  timeout: 2000,
  show: false,
}

const isWeixin = function(){
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

const alert =  function({color, msg}){
  Object.assign(toast, {color, msg, show:true})
}

const img = function(imgPath) {
  var path = "";
  if (imgPath != undefined && imgPath != "") {
    if (imgPath.split(',').length > 1) {
      imgPath = imgPath.split(',')[0];
    }
    if (imgPath.indexOf("http://") == -1 && imgPath.indexOf("https://") == -1) {
      path = config.imgDomain + "/" + imgPath;
    } else {
      path = imgPath;
    }
  }
  return path;
}

const fullImg = function (imgPath) {
  var path = "";
  if (imgPath.indexOf("http://") == -1 && imgPath.indexOf("https://") == -1) {
    path = "http://" + imgPath;
  } else {
    path = imgPath;
  }
  return path
}

export default {
  isWeixin,
  toast,
  alert,
  img,
  fullImg
}



