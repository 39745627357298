import request from '@/js/request'

export function index(data) {
  return request({
    url: 'index',
    method: 'get',
    params: data
  })
}

export function friendLink(data) {
  return request({
    url: 'friend_links',
    method: 'get',
    params: data
  })
}

export function indexDemo(data) {
  return request({
    url: 'index_demos',
    method: 'get',
    params: data
  })
}

export function projectCategories(data) {
  return request({
    url: 'project_categories',
    method: 'get',
    params: data
  })
}

export function demos(data) {
  return request({
    url: 'demos',
    method: 'get',
    params: data
  })
}

export function demoDetail(data) {
  return request({
    url: 'demo_detail',
    method: 'get',
    params: data
  })
}

export function previousDemo(data) {
  return request({
    url: 'previous_demo',
    method: 'get',
    params: data
  })
}

export function nextDemo(data) {
  return request({
    url: 'next_demo',
    method: 'get',
    params: data
  })
}

export function news(data) {
  return request({
    url: 'news',
    method: 'get',
    params: data
  })
}

export function newsDetail(data) {
  return request({
    url: 'news_detail',
    method: 'get',
    params: data
  })
}

export function nextNews(data) {
  return request({
    url: 'next_news',
    method: 'get',
    params: data
  })
}

export function previousNews(data) {
  return request({
    url: 'previous_news',
    method: 'get',
    params: data
  })
}

export function relateNews(data) {
  return request({
    url: 'relate_news',
    method: 'get',
    params: data
  })
}

export function address(data) {
  return request({
    url: 'address',
    method: 'get',
    params: data
  })
}
