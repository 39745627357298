<template>
  <div>
    <div class="nav">
      <div class="w">
            <div class="dis nav_box">
                <div class="logo" @click="toHome"></div>
                <div class="dis title_box">
                    <div :class="['title_item',idx==navCurrent?'title_item_active':'']" v-for="item,idx in navList" :key="idx" @click="toPage(idx)">{{item.name}}</div>
                    <!-- <div :class="['title_item',]" v-for="item,idx in navList" :key="idx" @click="toPage(idx)">{{item.name}}</div> -->
                </div>
                <div class="dis phone">
                    <div class="phone_bg">
                        <span></span>
                        <span></span>
                        <span></span>
                        <img class="img_40" src="../../assets/img/phone_red.png" alt="">
                    </div>
                    <div class="phone_num">400-678-0987</div>
                </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name:'Header',
    data() {
        return {
            navCurrent:localStorage.getItem('nav'),//控制当前是哪个nav 0 首页
            navList:[{name:'Home 首页'},{name:'Service 服务'},{name:'Case 案例'},{name:'News 智库/资讯'},{name:'About us 关于我们'},]
        }
    },
    methods:{
        toPage(num){
            this.navCurrent=num
            localStorage.setItem('nav',num)
            let path={
                0:'/',//首页
                1:'/service',//服务
                2:'/case',//案例
                3:'/news', //智库/资讯
                4:'/about',//关于我们
            }
            this.$router.push({path:path[num]})
        },
        toHome(){
            this.navCurrent=null
            localStorage.removeItem('nav')
            this.$router.push({path:'/'})
        },
    },
    updated() {
      this.navCurrent = localStorage.getItem('nav')
    },
    watch: {
      $route(to) {
        this.navCurrent = localStorage.getItem('nav')
      }
    },
}
</script>

<style lang="scss" scoped>

.nav{
    position: absolute;
    top: 0;
    width: 100%;
    height: 1.1rem;
    z-index: 10;
}

.nav_box{
    height: 100%;
}
.logo{
    width: 2.6375rem;
    height: .63rem;
    background-image: url('../../assets/img/logo_0.png');
    background-size: 100%;
    margin-right: 2.1625rem;
    cursor: pointer;
}

.title_box{
    width: 10.825rem;
    font-size: .225rem;
    // font-weight: 800;
    line-height: .3125rem;
    color: #FFFFFF;

    .title_item{
        width: 1.95rem;
        cursor: pointer;
    }
    .title_item:nth-child(1){
        margin-right: .0875rem;
    }
    .title_item:nth-child(2){
        margin-right: .25rem;
    }
    .title_item:nth-child(4){
        margin-right: .625rem;
    }
    .title_item:last-child{
        width: 2rem;
    }
    .title_item:hover{
        font-weight: 800;
    }

    .title_item_active{
        font-weight: 800;
    }
}

.phone{
    width: 3.5rem;
    height: .75rem;
    margin-left: 1rem;


    .phone_bg{
        position: relative;
        width: .75rem;
        height: .75rem;
        border-radius: 50%;
        // animation: scaleAnmation 2s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;

        span{
            width: 1rem;
            height: 1rem;
            position: absolute;
            // top: 5px;
            z-index: -1;
            border-radius: 50%;
            background-color: rgb(194, 14, 47);
            -webkit-transform: scale(0);
            -moz-transform: scale(0);
            -ms-transform: scale(0);
            transform: scale(0);
            -webkit-animation: circleScale 3s linear infinite;
            -moz-animation: circleScale 3s linear infinite;
            -ms-animation: circleScale 3s linear infinite;
            animation: circleScale 3s linear infinite;
        }

        span:nth-of-type(2) {
            animation-delay: 1s;
        }

        span:nth-of-type(3) {
            animation-delay: 2s;
        }

        .img_40{
            width: .5rem;
            height: .5rem;
        }
    }

    .phone_num{
        font-size: .3rem;
        font-weight: bold;
        line-height: .3875rem;
        color: #FFFFFF;
        letter-spacing: .025rem;
        margin-left: .25rem;
    }
}

@keyframes scaleAnmation{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.28);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes scaleAnmation2{
    0%,50%{
        transform: scale(1);
    }
    25%,75%{
        transform: scale(0.88);
    }
}


@keyframes circleScale{
    0%{
        opacity: 1;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    100%{
        opacity: 0;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
</style>
