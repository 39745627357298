import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/HomeView'

Vue.use(VueRouter)

const routes = [

  // {
  //   path: '/',
  //   component: HomeView,
  //   children:[
  //     {path:'/',component:()=>import('../views/HomeMain')},
  //   ]
  // },
  // {
  //   path: '/service',
  //   redirect: '/service',
  //   component:()=>import('../views/Service/First.vue'),
  //   children:[
  //     {path:'/service',component:()=>import('../views/Service')},
  //   ]
  // },
  // {
  //   path: '/case',
  //   redirect: '/case',
  //   component:()=>import('../views/Case/First.vue'),
  //   children:[
  //     {path:'/case',component:()=>import('../views/Case')},
  //     {path:'/caseone',component:()=>import('../views/Case/CaseOne.vue')},
  //   ]
  // },
  // {
  //   path: '/news',
  //   redirect: '/news',
  //   component:()=>import('../views/News/First.vue'),
  //   children:[
  //     {path:'/news',component:()=>import('../views/News')},
  //     {path:'/newsdetail',component:()=>import('../views/News/NewsDetail')},
  //   ]
  // },
  // {
  //   path: '/about',
  //   redirect: '/about',
  //   component:()=>import('../views/About/First.vue'),
  //   children:[
  //     {path:'/about',component:()=>import('../views/About')},
  //   ]
  // }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }

  {
    path: '/',
    component: Layout,
    children:[
      {path:'/',component:()=>import('../views/HomeMain')},
      {path:'/service',component:()=>import('../views/Service')},
      {path:'/case',component:()=>import('../views/Case')},
      {path:'/caseone',component:()=>import('../views/Case/CaseOne.vue')},
      {path:'/news',component:()=>import('../views/News')},
      {path:'/newsdetail',component:()=>import('../views/News/NewsDetail')},
      {path:'/about',component:()=>import('../views/About')},
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/ErrorPage/404'),
    hidden: true
  },
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior:()=>({x:0,y:0}),
  routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
