<template>

</template>

<script>
export default {
    name:'OnlineChat',
    props:['scrollH'],
    methods:{
      showWX(v){
        this.$refs.cardref1.style.display=v?'flex':'none'
      },
      showPhone(v){
        this.$refs.cardref0.style.display=v?'block':'none'
      },
    }
}
</script>

<style lang="scss" scoped>

.online{
    position: fixed;
    top: 43%;
    right: 0;
    z-index: 9999;
}
.box_one{
    width: 1.675rem;
    height: .85rem;
    background: rgb(203,0,18);
    border-radius: .05rem 0px 0px .05rem;
    font-size: .2rem;
    color: #FFFFFF;
    cursor: pointer;

    img {
        width: .475rem;
        height: .475rem;
        margin-left: .2rem;
        margin-right: .1rem;
    }
}

.box{
    width: .85rem;
    height: .85rem;
    justify-content: center;
    background: rgb(203,0,18);
    border-radius: .05rem 0px 0px .05rem;
    margin-top: .05rem;
    margin-left: .825rem;
    cursor: pointer;

    img{
        width: .475rem;
        height: .475rem;
    }
}
.box_2{
     margin-top: .05rem;
}
.deg{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(100%);
  width: 0;
  height: 0;
  border-width: .125rem;
  border-style: solid;
  border-color: transparent  transparent transparent #fff;
}
.card_s{
  position: absolute;
  right: 1.1rem;
  width: 2.9rem;
  height: 2.74rem;
  padding: .125rem;
  background: #fff;
  box-sizing: border-box;
  display: none;
  align-items: center;
  flex-direction: column;

  .card_s_img{
    width: 2.0rem;
    height: 2.0rem;
    margin-bottom: .1rem;

  }

  .card_s_word{
    height: 0.3rem;
    font-size: 0.18rem;
    color: #000;
    margin:0.15rem  auto auto 0.2rem;
    font-weight: 500;
  }
  .phone_num{
    /*width: 1.75rem;*/
    height: 0.3rem;
    font-size: 0.25rem;
    margin-left: 0.2rem;
    font-weight: bold;
    font-family: DIN;
    color: #005BAB;
    letter-spacing: 0.03rem;
  }
}
.card_s_2{
  width: 2.6rem;
  height: 1.4rem;
  background: #FFFFFF;
  border-radius: 0.05rem 0.05rem 0.05rem 0.05rem;
  opacity: 1;
}
.card_s_word2{
  text-align: center;
  font-size: 0.18rem;
  font-weight: 500;
  line-height: 0.3rem;

}
.card_s_word3{
  text-align: center;
  font-size: 0.15rem;
  font-weight: 400;
  line-height: 0.3rem;
  color:#999999
}
</style>
