<template>
  <div class="home">
    <Header :class="headerFixed||headerFixed2?'isFixed':''"/>
     <OnlineChat  />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import OnlineChat from '@/components/OnlineChat'
import Footer from '@/components/Footer'

export default {
  name: 'HomeView',
  components:{
    Header,
    OnlineChat,
    Footer
  },
  data() {
    return {
       headerFixed:'',
       headerFixed2:'',
    }
  },
  mounted() {
    // handleScroll为页面滚动的监听回调
    window.addEventListener("scroll",this.handleScroll)
    if(this.$route.path == '/caseone'){
      this.headerFixed2 = true
    }
  },
  methods:{
     // 吸顶
    handleScroll(){
      let scrollTop=window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop
      //  1431
      if(scrollTop > 0){
        this.headerFixed=true
      }else{
        this.headerFixed=false
      }
    },

  },
  updated(){
    this.headerFixed2=localStorage.getItem('fixed')
  },
 destroyed(){
  // 移除handleScroll为页面滚动的监听回调
  window.removeEventListener("scroll",this.handleScroll)
 },



}
</script>


<style lang="scss" >
.isFixed{
  position: sticky !important;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .nav{
    background: rgb(255,255,255);
    box-shadow: 0rem .0375rem .075rem rgba(0,0,0,0.16);
  }


  .logo{
    background-image: url('../assets/img/logo_1.png');
  }

  .title_item{
    color: #000000;
  }

  .phone_num{
    color: #000000 !important;
  }
}
</style>
