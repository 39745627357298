import Vue from 'vue';

import { Button,Carousel,CarouselItem,Pagination,Breadcrumb,BreadcrumbItem, Container, Footer, Header, Image } from 'element-ui';

Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem )
Vue.use(Pagination )
Vue.use(Breadcrumb )
Vue.use(BreadcrumbItem )
Vue.use(Container )
Vue.use(Footer )
Vue.use(Header )
Vue.use(Image )
