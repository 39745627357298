<template>
  <div id="app">
<!--    <OnlineChat  />-->
    <router-view/>
  </div>
</template>


<script>
// import OnlineChat from '@/components/OnlineChat'
export default {
  components:{
    // OnlineChat,
  },
}
</script>
<style lang="scss">
*{
  margin: 0;
  padding: 0;
}
body{
  background: rgb(255,255,255);
  font: 12px/1.5 PingFang SC,Microsoft YaHei,Heiti SC,tahoma,arial,Hiragino Sans GB,"\5B8B\4F53",sans-serif;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  // text-align: center;
  // color: #2c3e50;
}

// 版心
.w{
  width: 20rem;
  height: 100%;
  margin: 0 auto;
}

// 公共样式
.dis{
  display: flex;
  align-items: center;
}

.head_pos{
  font-size: .225rem;
  color: #A0A0A0;
}

a {
	text-decoration: none; /* 去除默认的下划线 */
	outline: none;	/* 去除旧版浏览器的点击后的外虚线框 */
	color: #000;	/* 去除默认的颜色和点击后变化的颜色 */
}


</style>
